.top-rows {
    border: 1px solid #BEC0C7;
    border-radius: 7px;
    margin-top: 10px;
    width: 95%;
}

.fullname {
    margin-top: 35px;
    font-size: 25px;
    font-weight: 700;
    color: black;
}

.surname {
    margin-left: 7px;
}

.job {
    margin-top: 15px;
    font-size: 19px;
    font-weight: 600;
}

.address {
    margin-top: 5px;
    font-size: 18px;
    color: #BEC0C7;
}

.edit {
    display: flex;
    justify-content: space-evenly;
    font-size: 18px;
    border-radius: 7px;
    border: 2px solid #BEC0C7;
    padding: 5px 20px 7px 10px;
    margin-top: 20px;
    margin-left: 100px;
    color: #BEC0C7;
}

.edit-span {
    font-size: 20px;
    margin-left: 6px;
}

.edit-icons {
    font-size: 20px;
    margin-left: 6px;
    margin-top: 2px;
}

.change {
    display: flex;
    justify-content: space-evenly;
    font-size: 18px;
    border: 2px solid #BEC0C7;
    border-radius: 7px;
    padding: 5px 28px 7px 10px;
    margin-left: 40px;
    color: #BEC0C7;
    margin-bottom: 17px;
}

.change-span {
    font-size: 15px;
    margin-left: 6px;
}

.change-icons {
    font-size: 16px;
    margin-left: 6px;
    margin-top: 2px;
}

.line {
    border: 1px solid #BEC0C7;
    font-weight: bold;
    width: 96%;
    margin-left: 13px;

}

.personal {
    margin-top: 5px;
    font-size: 20px;
    font-weight: 600;
    color: black;
}

.info {
    margin-top: 30px;
}

.field {
    font-size: 20px;
    color: #BEC0C7;
}

.ans {
    font-size: 18px;
    font-weight: 500;
}

.low-row {
    margin-bottom: 5%;
    margin-top: 30px;
}