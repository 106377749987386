/* a:hover,
a:active{
  text-decoration: none;
} */
.date {
  margin-left: 30px;
}

.cards {
  display: flex;
}

.card {
  padding: 2rem;
  width: 100%;
  border-radius: 0;
  justify-content: center;
  /* align-items: center; */
  line-height: 2;
  border: none;
  height: auto;
}

.card--1 {
  color: white;
  background-color: #145cd9;
}

.patient_name {
  font-size: 24px;
  font-weight: 600;
  line-height: 28.13px;
}

.list-flex {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.sub-title {
  opacity: .7;
}

.sub-text {
  font-weight: 600;
}

.nav-tab {
  width: 100%;
  margin-bottom: 2rem;
}

.nav-tabs {
  margin: 0;
  display: flex;
  font-size: 1.5rem;
  font-weight: 600;
  /* gap: 2rem; */
  justify-content: space-between;
}

.nav-tab-d {
  margin: 0;
  flex-direction: column;
  display: flex;
  font-size: 1.5rem;
  font-weight: 600;
  align-items: flex-start;
  justify-content: flex-start;
  /* border: 2px solid #333333; */
}

/* .nav-tabs-d{
  
} */

.nav-tab-d .nav-item-d {
  border: 1px solid brown;
  flex: 1;
  padding: 8px 14px;
}

.nav-tab-d .nav-item-d a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: block;
  text-align: start;
}


.dropdown-button {
  cursor: pointer;
  background-color: #888;
  color: #111;
  border-radius: 70%;
  padding: 2px;
  margin-left: 10px;
  /* font-size: 18px; */
}

.dropdown-list {
  position: absolute;
  top: 100%;
  left: 100%;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  display: none;
  /* Add other styles as needed */
}

.show-dropdown .dropdown-list {
  display: block;
}


/* .nav-tabs-d{
  margin: 0;
  display: flex;
  font-size: 1.5rem;
  font-weight: 600;
  flex-direction: column;
  align-items: start;
}

.nav-tabs-d .nav-item{
  flex: 1;
  border: 1px solid brown;
}

.nav-tabs-d .nav-item a{
  width: 100%;
  display: block;
} */

.nav-item Link {
  color: black;
  /* width: 100%; */
  opacity: 0.5;
  border-bottom: 3px solid transparent;
}



/* .nav-item:hover{ */
/* } */

.nav-item .act {
  opacity: 1;
  border-bottom: 3px solid #145cd9;
  color: #145cd9;
}

.nav-item .act:hover {
  border-bottom: 3px solid #145cd9;

}

.tab--2 {
  display: flex;

}

.tab--2 .nav-item {
  width: 50%;
  text-align: center;
}

.tab--2 .act {
  border-bottom: none;
  color: #145cd9;
  background-color: #FFFFFF;
}

.tab--2 .act:hover {
  border-bottom: none;
  background-color: #FFFFFF;
}


.treatment-tab {
  /* margin-top: 10rem; */
  /* margin: 5rem  5rem;  */

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  margin: auto;

}

.treatment-tab--sub {
  margin-top: 2rem;
  width: 100%;
  display: flex;
  /* gap: 5rem; */
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3rem;
}

.treatment-tab--sub span {
  color: #145cd9;
  font-weight: 600;
}

.opt-tab .select {
  border: 2px solid #145cd9;
  color: #145cd9;
}

.treatment-tab .nav-tabs {
  width: 100%;
  justify-content: start;
}

.treatment-tab .trtmnt .nav-item {
  margin-right: 1rem;
}

.nav-item .sel {
  opacity: 1;
  border: 2px solid #145cd9 !important;
  color: #145cd9;
}

.hidden {
  display: none;
}

.visible {
  display: block;
}



.toothcard {
  display: flex;
  justify-content: space-around;
  width: 300px;
  margin-top: 50px;
}

.toothnum {
  width: 50px;
  border-radius: 16px;
  height: 50px;
  padding: 17px;
  background-color: #F8F8F8;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

}

.toothcard2 {
  display: flex;
  justify-content: space-evenly;
  padding: 27px;
  background-color: white;
  width: 445px;
  height: 120px;
  margin-top: 28px;
  margin-left: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;

}


/* ---------------- dropdown color --------- */

.make {
  color: #F4F4F4;
  background-color: #145BD9;
  height: 32px;
  border-radius: 4px;
}

.cancel {
  background-color: #FA8B7A66;
  color: #333;
  height: 32px;
  border-radius: 4px;
}

.inprogressC {
  display: inline-block;
  min-width: 10px;
  padding: 7px 8px;
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  color: #AC3207;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  background-color: #FDDBD6;
  border-radius: 10px;
}

.completedC {
  display: inline-block;
  min-width: 10px;
  padding: 7px 8px;
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  color: #097621;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  background-color: #D6ECDB;
  border-radius: 10px;
}

.notYet {
  display: inline-block;
  min-width: 10px;
  padding: 7px 8px;
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  color: #BD3DF9;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  background-color: #F5DCF9;
  border-radius: 10px;
}

.makeap {
  display: inline-block;
  min-width: 10px;
  padding: 7px 8px;
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  color: #333;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  background-color: #FFFFFF;
  border-radius: 10px;
}

.canap {
  display: inline-block;
  min-width: 10px;
  padding: 7px 8px;
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  color: #333;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  background-color: #FFFFFF;
  border-radius: 10px;
}

.resap {
  display: inline-block;
  min-width: 10px;
  padding: 7px 8px;
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  color: #333;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  background-color: #FFFFFF;
  border-radius: 10px;
}




.accordion-header {
  background-color: white;
  color: black;
  font-size: 20px;
  cursor: pointer;
  padding: 20px;
  border-bottom: 1px solid #ddd;
  margin: 20px;
  height: auto;

}

.accordion-header.active {
  background-color: white;
}

.accordion-content {
  padding: 10px;
  height: auto;
}


.tabflex {
  display: flex;
  justify-content: space-evenly;
}


.tab-name {
  display: flex;
  gap: 1rem;
}

.treatmentPlan {
  display: flex;
  gap: 2rem;
}


/*------------------ prescription css start */
/*---------------------------------------------- */
.mt10 {
  margin-top: 7px
}

.presbtn {
  margin-top: 17px;
  margin-left: 9px;
}

.mt20 {
  margin: 20px
}


/*------------------- medical inventory */

.typecard {
  align-items: center;
  /* text-align: center; */
  width: auto;
  height: auto;
  /* margin-right: 20px; */
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  /* display: flex; */
  /* justify-content: space-around; */
  background-color: white;
  padding: 1rem;
  gap: 1rem;
}

@media screen and (max-width: 1200px) {
  .typecard {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* flex-direction: column;  */
  }
}

.typedisplay {
  display: flex;
  margin-left: 30px;
  margin-top: 40px;
  margin-bottom: 40px;
}

.medicard {
  width: 95%;
  height: auto;
  background-color: white;
  margin-top: 15px;
  border-radius: 7px;
  padding: 10px;
  margin-left: 20px
}

.medirow {
  margin-top: 30px;
  padding: 29px;
}

.medibtn {
  margin-top: 5px;
  margin-left: 20px;
}

.treatment {
  width: 100%;
  /* /* display: flex; */
  /* justify-content: space-around;  */
  align-items: center;
  gap: 5rem;
  margin: 20px;
}

.treatmenthead {

  display: flex;
  justify-content: space-around;
  background-color: #D6ECDB;
  color: black;
  font-weight: bold;
}

.label-gap {
  display: flex;
  justify-content: space-evenly;
  /* align-items: center;  */
  width: 100%;
  margin: 8px;
}

.label-gap label {
  width: 100%;
  margin: 0;

}

.label-gap input {
  width: 100%;
  outline: none;
  margin-right: 50px;
}

.label-div {

  display: flex;
  margin: 15px 0px;
  justify-content: space-around;
  align-items: center;
}

/* ---------------------  INVOICE CSS ------------------------ */
#getinv {
  background-color: #bd9208;
  border: #bd9208 solid 1px;
}

#mtop {
  margin-top: 40px;
}

#emptydiv {

  height: 100px;
}

.title {

  font-size: 17px;
  font-weight: bold;
}

.title1 {
  font-size: 14px;
  margin-top: 2px;
}

.invoice {
  font-size: 22px;
  font-weight: bold;
  color: black;
}

.invoice-id {
  font-size: 15px;
  margin-top: 0px;
}

.patient-title {
  font-size: 15px;
  margin-top: 35px;
  font-weight: bold;
  color: black;
}

.address-title {
  font-size: 15px;
  color: black;
  font-weight: bold;
}

.invoice-date {
  margin-top: 53px;
  font-weight: bold;
  color: black;
  text-align: right;
}

.patient-id {
  font-weight: bold;
  color: black;
  text-align: right;
}

.tableheading {
  /* style={{ backgroundColor:'black',color:'white'}} */
  background-color: black;
  color: white;
}

.tabledata {
  font-weight: bold;
  font-size: 15px;
  text-align: right;
}

.tableoutput {
  font-size: 13px;
}

.line1 {
  border-left: 3px solid rgb(189, 146, 8);
  height: 50px;
  margin-left: 30px;
}

.line2 {
  border-left: 3px solid rgb(189, 146, 8);
  height: 50px;
  margin-right: 30px;
}

.invoice-footer-icons {
  margin-right: 5px;
  color: white;
  background-color: rgb(189, 146, 8);
  border-radius: 50%;
  width: 21px;
  height: 21px;
  padding: 3px;
}

#invoice-footer-sec {
  display: flex;
  justify-content: space-evenly;
  border-top: 2px solid black;
  border-top-width: 50%;
  margin-left: 10px;
}

#invoice-footer-div {

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: 'black'
}

.invoice-footer-span {
  display: flex;
  height: 30px;
  align-items: center;

}


.invoice-footer-mail {

  margin-right: 40px;
  display: flex;
  align-items: center;
}

#box {
  margin-left: 15px;
}

#box1 {

  background-color: black;
  height: 27px;
}

#box2 {

  background-color: rgb(189, 146, 8);
  height: 27px;
}

.background-container {
  background-image: linear-gradient(rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)), url('assets/img/logo/invoice_back.png');
  background-repeat: no-repeat;
  background-position: center center;
  content: '';
  width: 100%;
  height: 100%;
}

.invoice-table {
  border-collapse: collapse;
  background-color: transparent;
  margin-top: 0px;
}

.invoicebaground {
  background-color: #FFFFFF;
  padding: 20px;
}

.card-tab-medical {
  border: 3px solid #145BD9 !important;
  /* background-color: #145BD9 !important; */
  color: #000000 !important;
  border-radius: 10px !important;

}


/* ----------------- Medical REPORT ICON -------------------- */
.medical-icons {
  display: flex;
  margin-right: 6px;
  align-items: center;
}

.photo-icon {
  background-color: #D6ECDB;
  border-radius: 50%;
  font-size: 13px;
  color: green;
  height: 25px;
  width: 25px;
  padding: 4px;
}

.xray-icon {
  background-color: lightgrey;
  border-radius: 50%;
  font-size: 13px;
  color: black;
  height: 25px;
  width: 25px;
  padding: 4px;
}

.blood-icon {
  background-color: #F7B6B1;
  border-radius: 50%;
  font-size: 13px;
  color: red;
  height: 25px;
  width: 25px;
  padding: 4px;
}

.opg-icon {
  background-color: lightgrey;
  border-radius: 50%;
  font-size: 13px;
  color: black;
  height: 25px;
  width: 25px;
  padding: 4px;
}

.nav-tabs {
  margin: 0;
  display: flex;
  font-size: 1.5rem;
  font-weight: 600;
  /* gap: 2rem; */
  justify-content: space-between;
}

.nav-item a {
  color: black;
  /* width: 100%; */
  opacity: 0.5;
  border-bottom: 3px solid transparent;
}

.nav-item .act {
  opacity: 1;
  border-bottom: 3px solid #145cd9;
  color: #145cd9;
}

.nav-item .act:hover {
  border-bottom: 3px solid #145cd9;

}

.treatment-tab .trtmnt .nav-item {
  margin-right: 1rem;
}

.tab--2 .act {
  border-bottom: none;
  color: #145cd9;
}

.tab--2 .act:hover {
  border-bottom: none;
}

.hidden {
  display: none;
}

.visible {
  display: block;
}

/* .inputChange{
  /* float: right; */
/* margin-left: 30%; */
/* } */

.treatmentInputChange {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.treatmentInputChange button {
  border: 1px solid salmon;
  padding: 0 2rem;
  font-size: 16px;
}

.btn-M {
  /* border: none; */
  border-right: .8px solid #333;
  /* border-top-right-radius: 2px;  */
}

.generalDentistry {
  width: 80vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: self-start;
  /* padding: 2rem 20rem; */
  /* margin: 3rem 0; */
  gap: 2rem;
  margin-bottom: 2rem;
}

.diagnosisType {
  font-size: 2rem;
}

.toothSpecific {
  width: 80vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: self-start;
  /* padding: 1rem 20rem; */
  /* margin-bottom: 20rem; */
}


.diagnosisSelList {
  /* width: 50vw; */
  /* margin: 0 28rem; */
  width: 80vw;
  /* margin: 0 3rem; */
  /* padding: 1rem 20rem; */
}

.teethClose {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.diagnosisName {
  display: flex;
  gap: 1rem;
  /* justify-content: center; */
  align-items: self-start;
  /* padding-right: 5px; */
  /* padding: 0 20rem; */
}



.diagnosisList {
  display: flex;
  gap: 1rem;
  flex-direction: column;
}

.selectedDiagnosis {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* .selectedDiagnosisName{
  display: flex;
  flex-direction: column;
  gap: 1rem ;
} */

.searchBar {
  /* outline: auto; */
  /* border: 1px solid #145BD9; */
  border-radius: 10px;
  /* background-color: #888; */
  width: 150px;
  height: 40px;
  margin-top: 20px;
  margin-left: 15px;
  -webkit-transition: width 0.4s ease-in-out;
  transition: width 0.4s ease-in-out;
}

.searchBar:focus {
  width: 300px;
}

.message-card {
  position: fixed;
  top: 50%;
  right: 50%;
  background-color: green;
  color: white;
  padding: 100px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  z-index: 9999;
}

.book-component {
  display: flex;
  gap: 1rem;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background-color: #f5f5f5;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin: 20px;
}

.book-component label {
  font-weight: bold;
}

.submit-btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem 7rem;
}

.dropdownMenu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
  padding: 2rem .5rem;
  background-color: #F5F6FA;
  border-radius: 10px;
  /* box-shadow: 2px 5px #888; */
}

.form-dropdown {
  width: 100%;
}

.treatmentPlanForm {
  width: 100%;
}

.price {
  margin-top: 2rem;
}

.formSave {
  margin-top: 1rem;
}

/* .paymentTable{
  overflow-x: scroll  ;
} */

@media screen and (max-width: 1185px) {
  .paymentTable {
    overflow-x: scroll;
  }
}


/* .presrow {
  margin-bottom: 20px;
}

.form-select,
.form-control {
  margin-top: 5px;
}

.presbtn {
  margin-top: 20px;
}

@media (max-width: 768px) {
  .presrow {
    flex-direction: column;
  }
} */

.prescard_outer {
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 50%; */
  /* height: auto; */
  background-color: white;
  /* margin-top: 30px; */
  border-radius: 7px;
  padding: 10px;
  /* margin-left: 20px; */
}

.presrow {
  margin-top: 15px;
  padding: 7px;
  margin-top: 30px;
  padding: 7px
}

.pres_select {
  font-size: 14px;
  height: 35px;
  width: 50%;
  border: 2px solid #e6e6e6;
  border-radius: 7px;
  margin-left: 8px;
}


.prescriptionForm {
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.presrow {
  display: flex;
  gap: 5rem;
  /* width: 90vw; */
  align-items: center;
  justify-content: center;
}

.presrow--2 {
  padding-top: 2rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem 5rem;
  /* align-items: self-start; */
  /* justify-content: start; */
}

.presrow-6 {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
}

.tdata {
  /* display: flex; */
  /* justify-content: center; */
  text-align: center !important;
  /* align-items: center; */
  /* border: 2px solid red; */
}

div.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

ul.pagination-list {
  list-style: none;
  display: flex;
  gap: 5px;
}

li.pagination-item {
  margin: 0;
}

a.pagination-link {
  display: inline-block;
  padding: 6px 8px;
  text-decoration: none;
  color: #333;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: background-color 0.3s;
}

a.pagination-link:hover {
  background-color: #ddd;
}

a.pagination-link.active {
  background-color: #007bff;
  color: #fff;
  border: 1px solid #007bff;
}



@media (min-width: 1100px) and (max-width: 3000px) {
  .generaldens {
    height: auto;
    display: flex;
    justify-content: center;
    gap: 1rem;

  }

  .toothSpecificName {
    padding: 2rem 0;
    display: flex;
    gap: 1rem;
    /* justify-content: center; */
  }
}

@media (min-width: 400px) and (max-width: 1101px) {
  .generaldens {
    height: auto;
    display: flex;
    gap: 1rem;

  }

  .toothSpecificName {
    padding: 2rem 0;
    display: flex;
    gap: 1rem;
  }
}






/* In App.css or a similar file */
@media print {
  .thead-custom {
      background-color: black !important;
      color: white !important;
  }
  
  th {
      background-color: black !important;
      color: white !important;
  }
}