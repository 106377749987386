.nav-tab-inventory {
  width: 90% !important;
  /* margin-bottom: 10rem; */
}

.nav-tabs {
  margin: 0;
  display: flex;
  font-size: 1.5rem;
  font-weight: 600;
  /* gap: 2rem; */
  justify-content: space-between;
  width: 100%;
}

.nav-item Link {
  color: black;
  opacity: 0.5;
  border-bottom: 3px solid transparent;


}

.card {
  border-radius: 10px !important;

}

.card-tab-invent {
  border: 2px solid #145BD9 !important;
  background-color: #145BD9 !important;
  color: #FFFFFF !important;
  border-radius: 10px !important;
}


/* .nav-item:hover{ */
/* } */

.nav-item .inventorytab {
  opacity: 1;
  border-bottom: none !important;
  color: #FFFFFF;
  text-decoration: none !important;
}

.coco {
  background-color: brown;
}

.nav-item .patientab:hover {

  text-decoration: none !important;
}

/* ------------------ popup for score tip page start ------- */
.po_sc_in {
  margin-left: 20%;
  margin-right: 20%;
  margin-top: 5%;
}

/* ------------------ popup for score tip page end ------- */