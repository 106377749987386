@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	html {
		font-family: 'DM Sans', sans-serif !important;
		font-feature-settings: 'kern' !important;
		-webkit-font-smoothing: antialiased;
		letter-spacing: -0.5px;
	}
}

.top-4 {
	top: 0rem;
}

input.defaultCheckbox::before {
	content: url(../src/assets/svg/checked.svg);
	color: white;
	opacity: 0;
	height: 16px;
	width: 16px;
	position: absolute;
	left: 50%;
	transform: translate(-50%, 0px);
}

input:checked.defaultCheckbox::before {
	opacity: 1;
}


.tab-container {
	width: auto;
	margin: 0 auto;
}

.tab-header {
	display: flex;
	justify-content: left;
	background-color: transparent;
	/* border-bottom: 1px solid ; */
}

.tab {
	/* padding: 10px 20px; */
	cursor: pointer;
}

.tab.active {
	background-color: transparent;
}

.tab-content {
	padding: 4px;
	background-color: transparent;
	border: 1px solid transparent;
	border-top: none;
}

.table-container {
	width: 300px;
	margin: 0 auto;
}

table {
	margin-top: 20px;
	/* margin-left: -225px; */
	width: 100%;
	border-collapse: collapse;
}

th,
td {
	padding: 8px;
	text-align: left;
	border-bottom: 1px solid #ccc;
}

th {
	background-color: #F5F6FA;
}


.list-container {
	width: 750px;
	height: 200px;
	overflow: auto;
	border: 1px solid #ccc;
	border-radius: 5px;
}

.scrollable-list {
	list-style-type: none;
	padding: 0;
	margin: 0;
}

.scrollable-list li {
	padding: 10px;
	border-bottom: 1px solid #ccc;
}

.scrollable-list li:last-child {
	border-bottom: none;
}


.model {
	background-color: #f5f5f5;
	border: 1px solid #ccc;
	padding: 10px;
	margin-top: 10px;
}

.model h2 {
	color: #333;
	font-size: 18px;
	margin-bottom: 5px;
}

.model p {
	color: #555;
	font-size: 14px;
}


.list-container {
	width: 100%;
	height: 218px;
	/* Set desired height for the scrollable area */
	overflow-y: auto;
	border: none;
}

.list-view {
	list-style: none;
	padding: 0;
	margin: 0;
}

.list-view li {
	background-color: lightgray;
	padding: 10px;
	margin-bottom: 5px;
}


.grid-container {
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	grid-template-rows: repeat(4, 1fr);
	gap: 10px;
	height: 267px;
}

.grid-item {
	background-color: white;
	padding: 30px;
	text-align: left;
}

.grid-item2 {
	background-color: #4318ff;
	padding: 30px;
	text-align: center;
	color: white
}

.patientcardheading {

	color: #9e9c9c;
}

.patientcardtext {
	font-weight: bold;
	margin-top: 4px;
}

/* ---------------- register form font family ------ */
.reg_form_fam {
	font-family: Poppins, Helvetica, sans-serif;
}

.form-control {
	height: 45px;
}

/* --------------- radio and check box css ------ */


/* The container */
.container-checkbox {
	display: block;
	position: relative;
	padding-left: 28px;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 14px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

/* Hide the browser's default checkbox */
.container-checkbox input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
}

/* Create a custom checkbox */
.container-checkbox .checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 20px;
	width: 20px;
	border-radius: 6px;
	/* border-color: #2196F3; */
	border: solid 1px #D0DEF6;
	background-color: #F5F6FA;
}

/* On mouse-over, add a grey background color */
.container-checkbox:hover input~.checkmark {
	background-color: #FFFFFF;
}

/* When the checkbox is checked, add a blue background */
.container-checkbox input:checked~.checkmark {
	background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.container-checkbox .checkmark:after {
	content: "";
	position: absolute;
	display: none;
}

/* Show the checkmark when checked */
.container-checkbox input:checked~.checkmark:after {
	display: block;
}

/* Style the checkmark/indicator */
.container-checkbox .checkmark:after {
	left: 6px;
	top: 2px;
	width: 7px;
	height: 11px;
	border: solid white;
	border-width: 0 3px 3px 0;
	transform: rotate(45deg);
}


/* The container */
.container-radio {
	display: block;
	position: relative;
	padding-left: 35px;
	margin-bottom: 0px;
	cursor: pointer;
	font-size: 12px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

/* Hide the browser's default radio button */
.container-radio input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
}

/* Create a custom radio button */
.container-radio .checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 20px;
	width: 20px;
	border: solid 1px #D0DEF6;
	background-color: #F5F6FA;
	border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.container-radio:hover input~.checkmark {
	background-color: #FFFFFF;
}

/* When the radio button is checked, add a blue background */
.container-radio input:checked~.checkmark {
	background-color: #2196F3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.container-radio .checkmark:after {
	content: "";
	position: absolute;
	display: none;
}

/* Show the indicator (dot/circle) when checked */
.container-radio input:checked~.checkmark:after {
	display: block;
}

/* Style the indicator (dot/circle) */
.container-radio .checkmark:after {
	top: 5px;
	left: 6px;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: white;
}


/* -------------------------------------------- */

.cal {
	background-color: transparent;
	border: none;
	text-decoration: underline;
	margin-left: 10px;
}


.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 20px;
}

.selected-items {
	list-style-type: none;
	padding: 0;
}

.selected-items li {
	display: flex;
	align-items: center;
	margin-bottom: 10px;
}

.selected-items li.selected-item {
	background-color: yellow;
}

.selected-items button {
	margin-left: 10px;
}

.available-items {
	list-style-type: none;
	padding: 0;
}

.available-items li {
	margin-bottom: 5px;
}

.dropdown-select {
	position: relative;
	display: inline-block;
}

.selected-item {
	padding: 10px;
	border: 1px solid #ccc;
	cursor: pointer;
}

.close-icon {
	margin-left: 5px;
	background: none;
	border: none;
	cursor: pointer;
}

.dropdown-menu {
	position: absolute;
	top: 100%;
	left: 0;
	margin-top: 5px;
	padding: 0;
	list-style-type: none;
	background-color: #fff;
	border: 1px solid #ccc;
	border-top: none;
	display: flex;
	flex-direction: column;
	max-height: 150px;
	overflow-y: auto;
}

.dropdown-menu li {
	padding: 10px;
	cursor: pointer;
}

.dropdown-menu li:hover {
	background-color: #f0f0f0;
}

.btn {
	background-color: #145bd9;
	width: auto;
	height: auto;
}

.btn2 {
	background-color: #dfe7f7;
	width: auto;
	height: auto;
}


.tabheading {
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.tabsize {

	background-color: white;
	padding: 6px;
	width: auto;
	height: auto;
	border-radius: 6px;
}

.tabcard {
	display: flex;
	justify-content: space-evenly;
	margin-top: 30px;
	width: 100%;
}

/* .dental-charting {
	border: 1px solid #cacaca;
	padding: 30px;
	margin: 50px;
} */

.chart {
	background: #fff;
	text-align: center;
}

.codes {
	border: 1px solid #cacaca;
}

/* -------------  table style css ------- */

.tablehead {
	background-color: #FAFAFA;
	border-radius: 3px;
}

.tablevalue {
	color: #808080;
}

.bordered-table {
	border-collapse: collapse;
	background-color: white;
	margin-top: 0px;
}


.bordered-table th,
.bordered-table td {
	border-bottom: 2px solid #FAFAFA;
	padding: 8px;
	text-align: center;
}

.bordered-table tr:last-child td {
	border-bottom: none;
}


.multiselect {
	display: flex;
	justify-content: space-evenly;
	padding: 27px;
	/* background-color: white; */

	/* margin-top: 28px; */
	/* margin-left: 15px; */


}

.xclose {
	/* margin-left: 97px; */
	float: right;
	margin-right: -12px;
	margin-top: -28px;
	font-weight: bold;
	color: #CCD2E3;
}

.acard {
	background-color: #fff;
	border: 1px solid #ccc;
	border-radius: 5px;
	padding: 18px;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
	cursor: pointer;
	width: auto;
	height: 30px;
	border-radius: 15px;
}

.card2 {
	background-color: #fff;
	border: 1px solid #ccc;
	border-radius: 5px;
	padding: 10px;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
	cursor: pointer;
	margin-bottom: 10px;
	width: auto;
	height: 30px;
	border-radius: 7px;
}

.acard.active {
	border: 2px solid #145BD9;
	color: #145BD9;
	border-radius: 15px;
	padding: 20px;

}

.card2.active {
	border: 2px solid #145BD9;
	color: #145BD9;
	border-radius: 7px;

}

.card .card-body {
	padding: 0px;
}

.card .card-title {
	font-size: 18px;
	font-weight: bold;
	margin-bottom: 5px;
}

.toothproblem {
	display: flex;
	justify-content: space-around;
}

.probname {
	margin-top: -13px;
	text-align: center;
}

.probcrd {
	height: auto;
	width: auto;
	padding: 5px;
	background-color: #F8F8F8;
	margin-top: 20px;
}

.probcrd:active {
	height: auto;
	width: auto;
	padding: 5px;
	background-color: #F8F8F8;
	margin-top: 20px;
	border-color: red;
}

/* ------------------------------------------------- badch color css --------- */
.badge_success {
	display: inline-block;
	min-width: 10px;
	padding: 7px 8px;
	font-size: 12px;
	font-weight: 700;
	line-height: 1;
	color: #097621;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	background-color: #D6ECDB;
	border-radius: 10px;
}

.badge_danger {
	display: inline-block;
	min-width: 10px;
	padding: 7px 8px;
	font-size: 12px;
	font-weight: 700;
	line-height: 1;
	color: #AC3207;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	background-color: #FDDBD6;
	border-radius: 10px;
}

.badge_blue {
	display: inline-block;
	min-width: 10px;
	padding: 7px 8px;
	font-size: 12px;
	font-weight: 700;
	line-height: 1;
	color: #145BD9;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	background-color: #D3DCEB;
	border-radius: 10px;
}

.badge_orange {
	display: inline-block;
	min-width: 10px;
	padding: 7px 8px;
	font-size: 12px;
	font-weight: 700;
	line-height: 1;
	color: #FF6C2E;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	background-color: #F3D9CE;
	border-radius: 10px;
}

.badge_vilot {
	display: inline-block;
	min-width: 10px;
	padding: 7px 8px;
	font-size: 12px;
	font-weight: 700;
	line-height: 1;
	color: #BD3DF9;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	background-color: #F5DCF9;
	border-radius: 10px;
}

.tablevalue_round {

	height: 30px;
	border-radius: 50%;
	color: #bb3504;
	background-color: #fddbd6;
	border: 2px solid #bb3504;
	width: 30px;
	text-align: center;
	padding: 3px;
}


/* ---------------- input underline ----- */



.input_under:focus {
	outline: none;
}

/* ---------------- summary  page card --------- */

.sum_card {
	width: 90% !important;
	margin-left: 55px;
	height: 30px;
	background-color: #F4F4F4;
	border-radius: 10px !important;
}

.su_card {
	align-items: inherit !important;
	width: 95% !important;
	margin-left: 55px;
}

.content {
	padding: 10px;
	margin-top: 20px;
	background-color: white;
}



/* ------------------ popup for score tip page start ------- */
.po_sc {
	margin-left: 10%;
	margin-right: 10%;
	margin-top: 5%;
}

/* ------------------ popup for score tip page end ------- */

/* --------------------  edit icon css start ---------- */
.edit_icon {
	color: #145CD9;
	transition: color 0.3s ease;
	margin: auto;
	font-size: 20px;
	cursor: pointer;
}

.edit_icon:hover {
	color: #145CD9;
	font-size: 21px;
}

.delete_icon {
	color: rgb(187, 53, 4);
	transition: color 0.3s ease;
	margin: auto;
	font-size: 20px;
	cursor: pointer;
}

.delete_icon:hover {
	color: rgb(187, 53, 4);
	font-size: 21px;
}

/* ------------- toast message css ---------- */
/* .foo_bar_log_error {
	background-color: #EB5757 !important;
	color: white !important;
	border-radius: 4px !important;
	padding-left: 20px !important;
}

.foo_bar_log_eil_wa {
	background-color: #F2C94C !important;
	color: white !important;
	border-radius: 4px !important;
	padding-left: 20px !important;
}

.foo_bar_log_Success {
	background-color: #27AE60 !important;
	color: white !important;
	border-radius: 4px !important;
	padding-left: 20px !important;
} */

/* ----------------------------------------- switch toogle css start  ----------------- */
.switch_to {
	position: relative;
	display: inline-block;
	/* width: 60px;
	height: 34px; */
	width: 35px;
	height: 20px;
}

.switch_to input {
	opacity: 0;
	width: 0;
	height: 0;
}

.slider_too {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	-webkit-transition: .4s;
	transition: .4s;
}

.slider_too:before {
	position: absolute;
	content: "";
	/* height: 26px;
	width: 26px;
	left: 4px;
	bottom: 4px; */
	height: 18px;
	width: 17px;
	left: 1px;
	bottom: 1px;
	background-color: white;
	-webkit-transition: .4s;
	transition: .4s;
}

.inpu_too:checked+.slider_too {
	background-color: #2196F3;
}



.inpu_too:checked+.slider_too:before {
	-webkit-transform: translateX(17px);
	/* -ms-transform: translateX(26px);
	transform: translateX(26px); */
}

/* Rounded sliders */
.slider_too.round {
	border-radius: 34px;
}

.slider_too.round:before {
	border-radius: 50%;
}

/* ----------------------------------------- switch toogle css end  ----------------- */

.dropdown {
	position: relative;
	display: inline-block;
	cursor: pointer;
}

.dots {
	width: 5px;
	height: 5px;
	background-color: #333;
	margin: 3px 0;
	border-radius: 50%;
	transition: background-color 0.3s;
}

.dropdown:hover .dots {
	background-color: #555;
}

.dropdown-content {
	display: none;
	position: absolute;
	background-color: #f9f9f9;
	min-width: 120px;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	z-index: 1;
	margin-left: -40px;
	/* padding: 10px; */
}

.dropdown:hover .dropdown-content {
	display: block;
}

.dropdown-content .lis {
	color: #333;
	padding: 12px 16px;
	text-decoration: none;
	display: block;
}

.dropdown-content .lis:hover {
	background-color: #ddd;
}

a:focus,
a:hover {
	color: #23527c;
	text-decoration: none;
}

.center {
	margin: auto;
	display: block;
	text-align: center;
}

.ellipsisicon {
	font-weight: bold;
	font-size: 25px;
	color: black;
	cursor: pointer;
}

tr:hover {
	background-color: #F5F5F5;
}

.notification-dot {
	position: absolute;
	top: 0;
	right: 0;
	width: 8px;
	height: 8px;
	background-color: red;
	border-radius: 50%;
}

.tablescroll {
	overflow-x: scroll;
}
.prescription-tablescroll {
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
}



/* ------------------ responsive query for login page -------------------------- */

@media (min-width: 0px) and (max-width: 767px) {
	.logincard1 {
		display: none;
	}

}

@media (min-width: 768px) and (max-width: 3000px) {
	.logincard2 {
		display: none;

	}
}

@media (min-width: 0px) and (max-width: 1030px) {
	.tabtable {
		overflow-x: scroll;
	}

	.taboption {
		font-size: 12px;
	}

	.popclose {
		margin-right: -110px;
	}
}



/* @media (min-width: 0px) and (max-width: 1300px) { */
.tabtablediagno {
	overflow-x: scroll;
}

/* } */


@media (min-width: 0px) and (max-width: 800px) {

	.gendergrid {
		display: grid !important;
	}

	.mart {
		margin-top: 10px;
	}
}

@media (min-width: 1031px) and (max-width: 2000px) {

	.popclose {
		margin-right: -230px;
	}
}


div.pagination {
	display: flex;
	justify-content: center;
	margin-top: 20px;
}

ul.pagination-list {
	list-style: none;
	display: flex;
	gap: 5px;
}

li.pagination-item {
	margin: 0;
}

a.pagination-link {
	display: inline-block;
	padding: 6px 8px;
	text-decoration: none;
	color: #333;
	background-color: #fff;
	border: 1px solid #ddd;
	border-radius: 4px;
	transition: background-color 0.3s;
}

a.pagination-link:hover {
	background-color: #ddd;
}

a.pagination-link.active {
	background-color: #007bff;
	color: #fff;
	border: 1px solid #007bff;
}

.flexdis {
	display: flex;
}

@media (min-width: 767px) and (max-width: 940px) {

	.flexdis {
		display: grid;
	}
}