.menuheading {
  color: #D6D9E4;
  font-size: 10px;
}


/* .settingcrd {
  background-color: white;
  width: 100%;
  height: 500px;
} */


.two-column-layout {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.column1 {

  padding: 20px;
  width: 200px;
  height: 100%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.column2 {

  padding: 20px;
  width: 825px;
  height: 100%;
  margin-left: -20px;
  background-color: #F7F7FD;
}


.nav-item {
  color: #A3AED0;
}

/* .nav-item .actt {
  width: 150px;
  opacity: 1;
  color: #145cd9;
  background-color: #F4F4F4 !important;
  font-size: 13px;
  border-radius: 7px;

} */

.drname {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  margin-top: 20px;
}

.gender {
  text-align: center;
  font-size: 15px;
  margin-top: 5px;
  color: #929398;
}

.social {
  display: flex;
  justify-content: space-evenly;
  margin-top: 20px;
}

.icon {
  height: 26px;
  width: 26px;
  border-radius: 6px;
  border: 1px solid #929398;
  opacity: 0.5;
}

.socialmedia {
  height: 26px;
  width: 80px;
  border-radius: 4px;
  border: 1px solid #929398;
  opacity: 0.5;
  padding: 3px
}

.heading {
  text-align: left;
  font-size: 12px;
  font-weight: bold;
  margin-top: 20px;
}

.details {
  font-size: 12px;
  margin-left: "10px";
  color: #929398;
}

.details2 {
  font-size: 12px;
  margin-left: 25px;
  color: #929398;
  font-weight: bold;
}

.specialcrd {
  height: 21px;
  width: 50px;
  border-radius: 3px;
  background-color: #A3AED0;
  opacity: 0.1;
}

.spename {
  font-size: 12px;
  color: #929398;
}

@media (min-width: 0px) and (max-width: 900px) {

  .detialbluec {
    display: none;
  }
}