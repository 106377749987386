.menuheading {
  color: #145BD9 !important;
  font-size: 16px;
  font-weight: 600;
}


.settingcrd {
  background-color: white;
  width: 100%;
  /* height: 500px; */
  height: auto;
}


.two-column-layout {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.column1 {

  padding: 20px;
  width: 200px;
  height: 100%
}

.column2 {

  padding: 20px;
  width: 825px;
  height: 100%;
  margin-left: -20px;
  background-color: white;
}


.nav-item {
  color: #A3AED0;
}

.nav-item .actt {
  /* width: 150px; */
  width: auto;
  opacity: 1;
  color: #FFFFFF !important;
  background-color: rgb(86, 96, 217) !important;
  font-size: 13px;
  border-radius: 7px;

}

.settingtab {
  color: #000000 !important;
  opacity: 1 !important;
}

@media (min-width: 0px) and (max-width: 1180px) {

  .setintablist1 {
    width: 25%;
  }

  .setintablist2 {
    width: 75%;
  }
}

@media (min-width: 1181px) and (max-width: 3050px) {

  .setintablist1 {
    width: 15%;
  }

  .setintablist2 {
    width: 85%;
  }
}