.nav-tab {
    width: 100%;
    /* margin-bottom: 10rem; */
}

.nav-tabs {
    margin: 0;
    display: flex;
    font-size: 1.5rem;
    font-weight: 600;
    /* gap: 2rem; */
    justify-content: space-between;
    color: #808080 !important;
}

.nav-item {
    color: #808080 !important;
}

.nav-item Link {
    color: #808080 !important;
    /* width: 100%; */
    opacity: 0.5;
    border-bottom: 3px solid transparent;
}



/* .nav-item:hover{ */
/* } */

.nav-item.actm {
    opacity: 1;
    border-bottom: 3px solid #145cd9;
    color: #FFFFFF !important;
    background-color: #145cd9 !important;

}

.nav-item .actm:hover {
    border-bottom: 3px solid #145cd9;

}


.nav-item2 {
    color: #1c1c20 !important;
}

.nav-item3 {
    color: white !important;
}


.nav-item2 .patientab {
    color: red
}

.tabwid {
    gap: 20px;
    width: 25%;
}

@media (min-width: 0px) and (max-width: 1050px) {

    .tabwid {
        gap: 7px;
        width: 35%;
    }
}

.tabhead {
    font-weight: 500;
    font-size: 22px;
}